import React from "react";
import { useAboutPageData } from "@staticQueries";
import { DefaultLayout } from "@layout";
import { SliceFullBleedImage } from "@molecules";
import { Slices } from "@organisms";

const AboutPage = pageProps => {
  const { hero, slices, meta } = useAboutPageData();

  return (
    <DefaultLayout {...pageProps} meta={meta}>
      {hero.heading && (
        <SliceFullBleedImage
          {...hero}
          textY="bottom"
          textX="center"
          size="news"
        />
      )}
      <Slices slices={slices} />
    </DefaultLayout>
  );
};

export default AboutPage;
